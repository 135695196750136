import {api} from '../api'

export const sellerApi = api.injectEndpoints({
  endpoints: (build) => ({
    search: build.query({
      query(params) {
        return {
          url: `/seller/search/`,
          method: 'GET',
          params: {
            ...params,
          },
        }
      },
    }),

    getPost: build.query({
      query: (id) => {
        return {
          url: '/bid/',
          method: 'GET',
          params: {
            page: 1,
            endpoint_id: '018a283e-6b2e-08ce-133b-59c4627c0633',
          }
        }
      },
      // providesTags: (_result, _err, id) => [{type: 'Posts', id}],
    }),
    updateSeller: build.query({
      query(payload) {

        const sellerData = payload.sellerData
        const merchant_id = payload.merchant_id
        return {
          url: `/seller/${sellerData.id}/`,
          method: 'PUT',
          data: {
            ...sellerData,
            gender: undefined,
            inn: undefined,
            place_of_birth: undefined,
            merchant_id,
          },
        }
      },
    }),
    createSeller: build.query({
      query(payload) {
        const sellerData = payload.sellerData
        const merchant_id = payload.merchant_id

        return {
          url: `/seller/`,
          method: 'POST',
          data: {
            ...sellerData,
            gender: undefined,
            inn: undefined,
            place_of_birth: undefined,
            merchant_id,
          },
        }
      },
    }),
    // updatePost: build.mutation({
    //   query(data) {
    //     const {id, ...body} = data
    //     return {
    //       url: `posts/${id}`,
    //       method: 'PUT',
    //       body,
    //     }
    //   },
    //   invalidatesTags: (post) => [{type: 'Posts', id: post?.id}],
    // }),
    // deletePost: build.mutation({
    //   query(id) {
    //     return {
    //       url: `posts/${id}`,
    //       method: 'DELETE',
    //     }
    //   },
    //   invalidatesTags: (post) => [{type: 'Posts', id: post?.id}],
    // }),
  }),
})
