import {createSlice} from "@reduxjs/toolkit"

const initialState = {
  bids: {
    loading: false,
    page: 1,
    count: 1,
    data: [],
  },
  updateBids: false,
  selectedBid: {
    loading: false,
    data: null,
  },
  selectedBidUpdate: {
    loading: false,
    data: null,
  },
  selectedBidDocumentStatus: {
    // id : {uploaded, removed,}
  },
  withdrawalRequest: {
    loading: false,
    data: null,
    errors: null,
  },
  preparedDocuments: {
    loading: false,
    data: null,
    errors: null,
  },
  documentNumber: {
    loading: false,
    data: null,
    errors: null,
  },
  approve: {
    loading: false,
    data: null,
  },
  reject: {
    loading: false,
    data: null,
    errors: null,
  },
  removeDocument: {
    loading: false,
    data: null,
    errors: null,
  },
  removeDocuments: {
    loading: false,
    data: null,
    errors: null,
  }
  // /bid/3fa85f64-5717-4562-b3fc-2c963f66afa6/approve/
}

const bidSlice = createSlice({
  name: 'bids',
  initialState,
  reducers: {
    fetch: (state, action) => {
      // fetch endpoints
    },
    create(state, action) {
      // create bid
    },
    createWithCar(state, action) {
      // create bid with car
    },
    createWithCarReport(state, action) {

    },
    update(state, action) {
      // update bid
    },
    edit(state, action) {
      // edit bid
    },
    delete(state, action) {

    },
    documentNumber(state, action) {

    },
    removeDocumentNumber(state, action) {

    },
    setBids(state, action) {
      const {payload} = action
      state.bids.data = payload.data
      state.bids.count = payload.count
    },
    updateBids(state, action) {
      const {payload} = action
      state.updateBids = payload
    },
    setSelectedBid(state, action) {
      const {payload} = action
      state.selectedBid.data = payload
      if (!payload) {
        state.selectedBidDocumentStatus = {}
      }
    },
    selectedBidUpdate(state, action) {
      const {payload} = action
      state.selectedBidUpdate.data = payload?.data
      state.selectedBidUpdate.error = payload?.error
    },
    setSelectedBidDocumentStatus(state, action) {
      const {payload} = action
      state.selectedBidDocumentStatus[payload.key] = payload.value
    },
    setLoading(state, action) {
      const {payload} = action
      state[payload.type].loading = payload.loading
    },
    setCheckRequisite(state, action) {
      const {payload} = action
      state.selectedBid.data.sbp_payments_check_receiver_enabled = payload.sbp_payments_check_receiver_enabled
    },
    addDocument(state, action) {
      //
    },
    removeDocument(state, action) {
      const {payload} = action
      state.removeDocument.data = payload
    },
    removeDocuments(state, action) {
      // const {payload} = action
      // state.removeDocuments.data = payload
    },
    setWithdrawalRequest(state, action) {
      const {payload} = action
      state.withdrawalRequest.data = payload
    },
    withdrawalRequest(state, action) {
      //
    },
    setPreparedDocuments(state, action) {
      const {payload} = action
      state.preparedDocuments.data = payload
    },
    prepareDocuments(state, action) {
      //
    },
    setDocumentNumber(state, action) {
      const {payload} = action
      state.documentNumber.data = payload
    },
    // setDocumentNumberList(state, action) {
    //   const {payload} = action
    //   state.documentsNumber.data = payload
    // },
    approve() {

    },
    setApprove(state, action) {
      const {payload} = action
      state.approve.data = payload.data
    },
    setUpdateBids(state, action) {
      const {payload} = action
      state.updateBids = payload
    },
    reject() {

    }
  },
})

const bidReducer = bidSlice.reducer

export {bidSlice, bidReducer}
