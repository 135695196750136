import {useFormContext, Controller} from 'react-hook-form';
// @mui
import TextField from '@mui/material/TextField';

// ----------------------------------------------------------------------

export default function RHFTextDelimiterField({
  name,
  helperText,
  type,
  inputMode,
  InputProps,
  inputProps,
  ...other
}) {
  const {control} = useFormContext();

  const formatNumber = (value) => {
    if (value === null || value === undefined || value === '') return '';
    const parts = value.toString().replace(/ /g, '').split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const decimalPart = parts[1] !== undefined ? `.${parts[1]}` : '';
    return integerPart + decimalPart;
  };

  const parseNumber = (value) => {
    if (value === null || value === undefined || value === '') return '';
    return value
      .toString()
      .replace(/[^0-9. ]/g, '')
      .replace(/(\s+)/g, ' ')
      .replace(',', '.')
      .replace(/(\.\d{3,})/g, (match) => match.slice(0, 3));
  };


  return (
    <Controller
      name={name}
      control={control}
      render={({field, fieldState: {error}}) => (
        <TextField
          {...field}
          fullWidth
          type={type === 'number' ? undefined : type}
          value={type === 'number' ? formatNumber(field.value) : field.value || ''}
          onChange={(event) => {
            const inputValue = event.target.value;
            if (type === 'number') {
              const parsedValue = parseNumber(inputValue);
              field.onChange(parsedValue);
              const formattedValue = formatNumber(parsedValue);
              event.target.value = formattedValue;
            } else {
              field.onChange(inputValue);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
          inputProps={{
            ...inputProps,
            inputMode: inputMode || (type === 'number' ? 'decimal' : undefined),
          }}
          InputProps={InputProps}
        />
      )}
    />
  );
}
