import {api} from '../api'

export const transportsApi = api.injectEndpoints({
  endpoints: (build) => ({
    fetchCarReports: build.query({
      query(params) {
        return {
          url: `/car-report/`,
          method: 'GET',
          params: {
            page: params.page,
            endpoint_id: params.endpoint_id,
            status: params.status !== 'all' ? params.status : undefined,
            ...params.date,
            ...params.dateRange,
            ...({search_query: params?.carNumber}),

            // ...(params?.carNumber?.length > 9 ? {
            //   car__vin_code: params?.carNumber
            // } : {
            //   car__number: params?.carNumber
            // })
          },
        }
      },
    }),
    fetchCarReportId: build.query({
      query(payload) {
        return {
          url: `/car-report/${payload.id}/`,
          method: 'GET',
        }
      },
    }),

    updateCarReport: build.query({
      query(payload) {
        return {
          url: `/car-report/${payload.id}/`,
          method: 'PUT',
          data: {
            ...payload
          },
        }
      },
    }),
    fetchCarId: build.query({
      query(payload) {
        return {
          url: `/car/${payload.id}/`,
          method: 'GET',
        }
      },
    }),
    checkCar: build.query({
      query(params) {
        const number = params?.number
        const merchant_id = params?.merchant_id
        return {
          url: `/car/`,
          method: 'GET',
          params: {
            ...(number?.length > 9 ? {vin_code: number} : {number}),
            ...(merchant_id ? {merchant_id} : {})
          },
        }
      },
    }),

    createCar: build.query({
      query(payload) {
        const number = payload?.number
        return {
          url: `/car/`,
          method: 'POST',
          data: {
            ...(number?.length > 9 ? {vin_code: number} : {number}),
            brand: payload.brand ? payload.brand : undefined,
            merchant_id: payload.merchant_id ? payload.merchant_id : undefined,
          },
        }
      },
    }),

    createDocument: build.query({
      query(payload) {
        const formData = new FormData()

        const id = payload?.id
        formData.append("id", id);
        return {
          url: `/car-report/${id}/prepare_document/`,
          method: 'POST',
          data: formData,
        }
      },
    }),

    createCarReport: build.query({
      query(payload) {
        return {
          url: `/car-report/`,
          method: 'POST',
          data: {
            ...payload
          },
        }
      },
    }),
  }),
})
