import {put, all, takeLatest, select, takeEvery} from "redux-saga/effects"
import {bidSlice} from './bidSlice'
import {network} from '../../utils/network'
import {appSlice} from '../app/appSlice'
import {id} from "date-fns/locale"
import {isArray} from "lodash"
import {v4 as uuidv4} from 'uuid';
import {bidNav} from "src/utils/bid-nav"

export const idMapping = {}

export function documentTempToID(tempId) {
  return idMapping[tempId] ? idMapping[tempId] : tempId
}

function* fetch({payload}) {
  yield put(bidSlice.actions.setLoading({type: 'bids', loading: true}))

  try {
    const {selectedEndpoint} = yield select(store => store.endpoint)

    const response = yield network.axios({
      method: 'GET',
      url: '/bid/',
      params: {
        page: payload.page,
        endpoint_id: selectedEndpoint.id,
      }
    })

    yield put(bidSlice.actions.setBids({
      data: response.data.results.map(b => ({
        ...b,
        status: b.is_approved === false && b.status === 'new' ? 'on_review' : b.status,
      })),
      count: response.data.count,
    }))
  } catch (e) {
    console.log('e', e)
    // yield put(authSlice.actions.logout())
  }
  yield put(bidSlice.actions.setLoading({type: 'bids', loading: false}))
}

function* edit({payload}) {
  yield put(bidSlice.actions.setSelectedBid(null))
  yield put(bidSlice.actions.setLoading({type: 'selectedBid', loading: true}))
  const {id} = payload

  try {
    const response = yield network.axios({
      method: 'GET',
      url: `/bid/${id}/`,
    })

    response.data.requisite_list = response.data.requisite_list.filter(req => req.is_active)
    response.data.document_list = response.data.document_list.filter(doc => doc.is_active)
    response.data.item_list.forEach(item => {
      item.catalogueItem = {
        ...item.catalogue_item.prices[0],
        ...item.catalogue_item,
      }
      item.catalogue_item = item.catalogueItem.name
      item.price = Number(item.price)
      // item.price_per_kg = Number(item.price_per_kg)
      item.weediness = Number(item.weediness)
      item.weight_gross = Number(item.weight_gross)
      item.weight_net = Number(item.weight_net)
      item.weight_tare = Number(item.weight_tare)
    })

    response.data.status = response.data.is_approved === false && response.data.status === 'new' ? 'on_review' : response.data.status
    // response.data.is_approved === true && response.data.status === 'error' && response.data.rejection_reason === 'Операция отклонена банком' ? 'error_rejected' : response.data.status

    yield put(bidSlice.actions.setSelectedBid(response.data))
  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'selectedBid', loading: false}));
  }
}

function* create() {
  yield put(bidSlice.actions.setLoading({type: 'bids', loading: true}));
  yield put(bidSlice.actions.setSelectedBid(null))

  const selectedEndpoint = yield select(store => store.endpoint.selectedEndpoint)

  const traceId = uuidv4()

  try {
    const response = yield network.axios({
      method: 'POST',
      url: '/bid/',
      //headers: {'x-trace-id': traceId},
      data: {
        "endpoint_id": selectedEndpoint.id,
      },
    })

    network.navigate(`/operations/edit/${response.data.id}`)
  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'bids', loading: false}));
  }
}

function* createWithCar({payload}) {
  const {car} = payload
  yield put(bidSlice.actions.setLoading({type: 'bids', loading: true}));
  yield put(bidSlice.actions.setSelectedBid(null))

  const selectedEndpoint = yield select(store => store.endpoint.selectedEndpoint)

  console.log('payload', payload)

  // try {
  //   const response = yield network.axios({
  //     method: 'POST',
  //     url: '/bid/',
  //     data: {
  //       "endpoint_id": selectedEndpoint.id,
  //     },
  //   })
  //   const bid = {
  //     ...response.data,
  //     car_id: payload.car ? payload.car.id : null,
  //     car_report_id: payload.car_report ? payload.car_report.id : null,
  //   }
  //   yield network.axios({
  //     method: 'PUT',
  //     url: `/bid/${bid.id}/`,
  //     data: bid,
  //   })
  //   network.navigate(`/operations/edit/${response.data.id}`)
  // } catch (e) {
  //   console.log('e', e)
  // } finally {
  //   yield put(bidSlice.actions.setLoading({type: 'bids', loading: false}));
  // }
}

function* createWithCarReport({payload}) {
  yield put(bidSlice.actions.setLoading({type: 'bids', loading: true}));
  yield put(bidSlice.actions.setSelectedBid(null))

  const selectedEndpoint = yield select(store => store.endpoint.selectedEndpoint)

  try {
    const response = yield network.axios({
      method: 'POST',
      url: '/bid/',
      data: {
        "endpoint_id": selectedEndpoint.id,
      },
    })
    const bid = {
      ...response.data,
      car_id: payload.car ? payload.car.id : null,
      car_report_id: payload.car_report ? payload.car_report.id : null,
    }
    yield network.axios({
      method: 'PUT',
      url: `/bid/${bid.id}/`,
      data: bid,
    })
    network.navigate(`/operations/edit/${response.data.id}`)
  } catch (e) {
    console.log('e', e)
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'bids', loading: false}));
  }
}

function* update({payload}) {
  yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: true}))
  // console.log(payload.price, "payload.price");

  const bid = {
    ...payload,
    ...(payload.item_list && {
      item_list: payload.item_list.map((item, index) => ({
        id: item.id,
        order: item.order,
        catalogue_item_id: item.catalogueItem.id,
        weediness: item.weediness,
        weight_gross: item.weight_gross,
        weight_net: item.weight_net,
        weight_tare: item.weight_tare,
        catalogue_price_per_unit: item.catalogue_price_per_unit,
        price_per_unit: item.price_per_unit,
        // price_per_kg: item.price_per_kg,
      })),
    }),
    car_id: payload.car ? payload.car.id : null,
    car_report_id: payload.car_report ? payload.car_report.id : null,
    seller_id: payload.seller ? payload.seller.id : null,
    payment_comment: payload.payment_comment,
    price: payload.price
  }

  try {
    const response = yield network.axios({
      method: 'PUT',
      url: `/bid/${payload.id}/`,
      data: bid,
    })

    response.data.requisite_list = response.data.requisite_list.filter(req => req.is_active)
    response.data.document_list = response.data.document_list.filter(doc => doc.is_active)
    response.data.item_list.forEach(item => {
      item.catalogueItem = {
        ...item.catalogue_item.prices[0],
        ...item.catalogue_item,
      }
      item.catalogue_item = item.catalogueItem.name
      item.price = Number(item.price)
      // item.price_per_kg = Number(item.price_per_kg)
      item.weediness = Number(item.weediness)
      item.weight_gross = Number(item.weight_gross)
      item.weight_net = Number(item.weight_net)
      item.weight_tare = Number(item.weight_tare)
    })

    response.data.status = response.data.is_approved === false && response.data.status === 'new' ? 'on_review' : response.data.status

    yield put(bidSlice.actions.selectedBidUpdate({
      data: {
        ...response.data,
        item_list: response.data.item_list,
      },
      error: null,
    }))

    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  } catch (e) {
    const {response} = e
    if (e.response.status === 400) {
      yield put(bidSlice.actions.selectedBidUpdate({
        error: {
          ...response.data,
        },
      }))
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: translate(response?.data?.message),
      }))
    } else {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: 'Что-то пошло не так',
      }))
    }
    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  }
}

function* addDocument({payload}) {
  const formData = new FormData()

  const dictionary = yield select(store => store.app.dictionary)

  formData.append("bid_id", payload.bid.id);
  formData.append("file_document_type_id", dictionary.fileDocuments[payload.type].id);
  formData.append("file", payload.file);

  yield put(bidSlice.actions.setSelectedBidDocumentStatus({
    key: payload.file.tempId,
    value: {
      uploaded: false,
    },
  }))

  try {
    const response = yield network.axios({
      method: 'POST',
      url: '/bid-file-document/',
      data: formData,
      headers: {"Content-Type": "multipart/form-data"},
    })

    yield put(bidSlice.actions.setSelectedBidDocumentStatus({
      key: payload.file.tempId,
      value: {
        uploaded: true,
      },
    }))

    idMapping[payload.file.tempId] = response.data.id
  } catch (e) {
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
  }
}

function* deleteBid({payload}) {
  const {bid, registryId} = payload
  yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: true}))

  const {listLink} = bidNav({pathname: window.location.pathname, registryId, })

  try {
    const response = yield network.axios({
      method: 'DELETE',
      url: `/bid/${bid.id}/`,
    })
    yield put(bidSlice.actions.updateBids(true))
    network.navigate(listLink)
    yield put(appSlice.actions.addMessage({
      type: 'success',
      text: 'Черновик успешно удален',
    }))
  } catch (e) {
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: e?.response?.data?.message,
      }))
      return
    }
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  }
}

function* removeDocument({payload}) {
  yield put(bidSlice.actions.setLoading({type: 'removeDocument', loading: true}))

  const {bid} = payload

  let id = payload?.file?.tempId ?? payload?.file
  if (id && idMapping[id]) {
    id = idMapping[id]
  }

  try {
    yield network.axios({
      method: 'DELETE',
      url: `/bid-file-document/${id}/`,
    })

    if (id && idMapping[id]) {
      delete idMapping[payload.file.tempId]
    }

  } catch (e) {
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Не удалось удалить документ',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'removeDocument', loading: false}))
  }
}

function* removeDocuments({payload}) {
  yield put(bidSlice.actions.setLoading({type: 'removeDocuments', loading: true}))

  const {bid, files = []} = payload

  const new_document_list = bid.document_list.filter((file) => {
    return files.every((doc) => file.id !== doc.document.id)
  })

  try {
    const x = files.map((doc) => {
      let id = doc.document.id
      return network.axios({
        method: 'DELETE',
        url: `/bid-file-document/${id}/`,
      })
    })

    yield all(x)

  } catch (e) {
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Не удалось удалить документ',
    }))
  } finally {

    yield put(bidSlice.actions.selectedBidUpdate({
      data: {
        ...bid,
        document_list: new_document_list
      }
    }))

    yield put(bidSlice.actions.setLoading({type: 'removeDocuments', loading: false}))
  }
}

function* withdrawalRequest({payload}) {
  const {bid, registryId} = payload
  yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: true}))
  yield put(bidSlice.actions.setLoading({type: 'withdrawalRequest', loading: true}))

  const filteredRequisiteList = bid.requisite_list.filter(r => r.requisite_type_name === 'pan' || r.requisite_type_name === 'phone')

  try {
    const response = yield network.axios({
      method: 'POST',
      url: `/withdrawal-request/`,
      data: {
        bid_id: bid.id,
        bid_requisite_id: filteredRequisiteList.length ? filteredRequisiteList.at(0).id : undefined,
      },
    })

    if (response.data.success === false) {
      if (response.data.message === 'Daily limits exceeded. Ask regional manager to approve this Bid') {
        yield put(appSlice.actions.addMessage({
          type: 'success',
          text: 'Превышен дневной лимит на выплаты. Операция отправлена на согласование',
        }))
      } else {
        yield put(appSlice.actions.addMessage({
          type: 'success',
          text: 'Операция отправлена на согласование',
        }))
      }

    }

    const {viewLink} = bidNav({
      pathname: window.location.pathname,
      registryId,
      id: bid.id
    })


    network.navigate(viewLink, {replace: true})
  } catch (e) {
    console.log('e', e)
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: translate(e?.response?.data?.message),
      }))
      return
    }
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'withdrawalRequest', loading: false}))
    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  }
}

function* prepareDocuments({payload}) {
  const {bid, itemClasses} = payload

  const group_item_list = []
  if (itemClasses && itemClasses.length) {
    const filteredClasses = itemClasses.filter(item => {
      return true
    })

    filteredClasses.forEach(i => {
      group_item_list.push({
        "scrap_class_id": i.scrap_class_id,
        "document_number": i.value_document_number ? i.value_document_number : null,
        "description": i.value_description ? i.value_description : null,
      })
    })
  }

  yield put(bidSlice.actions.setLoading({type: 'preparedDocuments', loading: true}))
  yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: true}))

  yield put(bidSlice.actions.setPreparedDocuments(null))

  try {
    const response = yield network.axios({
      method: 'POST',
      url: `/bid/${bid.id}/prepare_documents/`,
      data: {
        bid_id: bid.id,
        group_item_list,
      },
    })

    const docs = response.data.document_list
    const document = docs.find(document => document.is_active && document.file_document.document_type_name === 'offer_full')

    response.data.requisite_list = response.data.requisite_list.filter(req => req.is_active)
    response.data.document_list = response.data.document_list.filter(doc => doc.is_active)

    yield put(bidSlice.actions.setPreparedDocuments({
      bid: response.data,
      file: document.file_document.file,
    }))

    const newDocument_number_list = response.data.document_number_list

    yield put(bidSlice.actions.selectedBidUpdate({
      data: {
        ...bid,
        document_list: response.data.document_list,
        document_number_list: newDocument_number_list
      }
    }))

  } catch (e) {
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: translate(e?.response?.data?.message)
      }))
      return
    }
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'preparedDocuments', loading: false}))
    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  }
}

function* documentNumber({payload}) {
  // todo недоделано
  const {bid, scrap_class_id, document_number, description} = payload

  // console.log('documentNumber', payload)

  yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: true}))
  yield put(bidSlice.actions.setLoading({type: 'documentNumber', loading: true}))

  try {

    const a = bid.document_number_list && isArray(bid.document_number_list) ? bid.document_number_list.find(i => i.scrap_class_id === scrap_class_id) : null
    let response = null

    if (a) {
      response = yield network.axios({
        method: 'PUT',
        url: `/bid-document-number/${a.id}/`,
        data: {
          document_number: document_number ?? null,
          description: description ?? null,
        },
      })
    } else {
      response = yield network.axios({
        method: 'POST',
        url: `/bid-document-number/`,
        data: {
          document_number: document_number ?? null,
          description: description ?? null,
          bid_id: bid.id,
          scrap_class_id: scrap_class_id
        },
      })
    }

    const newDocument_number_list = response.data

    yield put(bidSlice.actions.setDocumentNumber(response.data))

    yield put(bidSlice.actions.selectedBidUpdate({
      data: {
        ...bid,
        document_number_list: [newDocument_number_list]
      }
    }))

  } catch (e) {
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: translate(e?.response?.data?.message)
      }))
      return
    }
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
    yield put(bidSlice.actions.setLoading({type: 'documentNumber', loading: false}))
  }
}


function* removeDocumentNumber({payload}) {
  const {bid, scrap_class_id, document_number} = payload

  yield put(bidSlice.actions.setLoading({type: 'documentNumber', loading: true}))

  try {

    const a = bid.document_number_list && isArray(bid.document_number_list) ? bid.document_number_list.find(i => i.scrap_class_id === scrap_class_id) : null

    if (a) {
      yield network.axios({
        method: 'DELETE',
        url: `/bid-document-number/${a.id}/`,
        data: {
          document_number: document_number ?? null,
        },
      })
      yield put(bidSlice.actions.setDocumentNumber({}))
    }


  } catch (e) {
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: translate(e?.response?.data?.message)
      }))
      return
    }
    console.log('e', e)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'documentNumber', loading: false}))
  }
}


function* approve({payload}) {
  const {bid, registryId} = payload
  yield put(bidSlice.actions.setLoading({type: 'approve', loading: true}))

  try {
    const response = yield network.axios({
      method: 'POST',
      url: `/bid/${bid.id}/approve/`,
      data: {
        ...(bid.code && {code: bid.code})
      }
    })

    yield put(bidSlice.actions.setApprove({
      data: {
        ...bid,
        ...response.data
      },
    }))

    if (response?.data?.is_approved || !response?.data?.is_approve_code_required) {
      const {listLink} = bidNav({pathname: window.location.pathname, registryId})

      network.navigate(listLink)
      yield put(appSlice.actions.addMessage({
        type: 'success',
        text: 'Операция подтверждена',
      }))
    }

  } catch (e) {

    if (e.response.data.message === 'Time between attempts has not yet passed') {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: 'Неверный код',
      }))
      return
    }
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: e.response.data.message,
      }))
      return
    }
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'approve', loading: false}))
  }
}

function* reject({payload}) {
  const {bid, registryId} = payload
  yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: true}))
  const {listLink} = bidNav({
    pathname: window.location.pathname,
    registryId
  })

  try {
    const response = yield network.axios({
      method: 'POST',
      url: `/bid/${bid.id}/reject/`,
    })
    network.navigate(listLink)
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Операция отклонена',
    }))
  } catch (e) {
    if (e?.response?.data?.message) {
      yield put(appSlice.actions.addMessage({
        type: 'error',
        text: e?.response?.data?.message,
      }))
      return
    }
    yield put(appSlice.actions.addMessage({
      type: 'error',
      text: 'Что-то пошло не так',
    }))
  } finally {
    yield put(bidSlice.actions.setLoading({type: 'selectedBidUpdate', loading: false}))
  }
}

export function* bidSagas() {
  yield takeLatest(bidSlice.actions.fetch.type, fetch)
  yield takeLatest(bidSlice.actions.create.type, create)
  yield takeLatest(bidSlice.actions.createWithCar.type, createWithCar)
  yield takeLatest(bidSlice.actions.createWithCarReport.type, createWithCarReport)
  yield takeLatest(bidSlice.actions.update.type, update)
  yield takeLatest(bidSlice.actions.edit.type, edit)
  yield takeLatest(bidSlice.actions.delete.type, deleteBid)

  yield takeEvery(bidSlice.actions.addDocument.type, addDocument)
  yield takeEvery(bidSlice.actions.removeDocument.type, removeDocument)
  yield takeEvery(bidSlice.actions.removeDocuments.type, removeDocuments)

  yield takeLatest(bidSlice.actions.withdrawalRequest.type, withdrawalRequest)
  yield takeLatest(bidSlice.actions.prepareDocuments.type, prepareDocuments)
  yield takeLatest(bidSlice.actions.documentNumber.type, documentNumber)
  yield takeLatest(bidSlice.actions.removeDocumentNumber.type, removeDocumentNumber)

  yield takeLatest(bidSlice.actions.approve.type, approve)
  yield takeLatest(bidSlice.actions.reject.type, reject)
}


const translate = (message) => {
  if (!message)
    return "Что-то пошло не так"

  const messages = {
    "User must have 'can_edit_bid' permission to update Bid": "У вас недостаточно прав для редактирования данной операции",
    "Seller is required": "Ошибка: не заполнен продавец",
    "BidRequisite list is empty": "Ошибка: не заполнены реквизиты выплаты",
    "BidItem price_per_unit must be positive": "Ошибка: цена за лом должна быть больше нуля",
    "Bid must have Seller": "Ошибка: не заполнен продавец"
  }

  return messages[message] ?? message
}
